import Placeholder from '@components/placeholder'

const ValuePropWhyPlaceholder = () => {
  const renderPlaceholderColumn = () => {
    return (
      <div className='flex w-full max-w-[450px] items-center gap-[10px] mt-0 mb-4 mx-auto last-of-type:mb-0 lg:flex-col lg:m-0 '>
        <Placeholder
          height='64px'
          width='64px'
          rounded
        />
        <div className='flex flex-col gap-2 w-full'>
          <Placeholder
            height='25px'
            width='100%'
            rounded
          />
          <Placeholder
            height='50px'
            width='100%'
            rounded
          />
        </div>
      </div>
    )
  }

  return (
    <div className='my-9'>
      <div className='flex flex-col items-center gap-[10px] max-w-[550px] mt-0 mx-auto mb-[37px] lg:mb-[20px]'>
        <div className='w-full lg:max-w-[400px]'>
          <Placeholder
            height='30px'
            width='100%'
            rounded
          />
        </div>
        <Placeholder
          height='94px'
          width='100%'
          rounded
        />
      </div>
      <div className='lg:flex lg:flex-row lg:gap-[20px] lg:justify-center'>
        {renderPlaceholderColumn()}
        {renderPlaceholderColumn()}
        {renderPlaceholderColumn()}
      </div>
    </div>
  )
}

export default ValuePropWhyPlaceholder

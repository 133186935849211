export const WHY_TITLE_FALLBACK = 'Why Student Beans?'
export const WHY_DESCRIPTION_FALLBACK =
  'We’re top-rated for a reason, and totally committed to bringing you the most exclusive deals you won’t get anywhere else, straight from your favourite brands. Get all of these perks and more…'

export const ONLY_ON_SB_FALLBACK = 'Only on Student Beans'
export const WONT_FIND_ANYWHERE_ELSE_FALLBACK =
  'Big brands and offers you won’t find anywhere else.'

export const DREAMY_DEALS_FALLBACK = '1000s of dreamy deals'
export const ENJOY_IN_ONE_PLACE_FALLBACK =
  'Enjoy thousands of student offers, all in one place.'

export const WHENEVER_WHEREVER_FALLBACK = 'Whenever, wherever'
export const GET_DISCOUNTS_FALLBACK =
  'Get discounts 24/7 on our website or must-have app.'

export const ONLY_ON_SB_KEY = 'onlyOnSB'
export const DREAMY_DEALS_KEY = 'dreamyDeals'
export const WHENEVER_WHEREVER_KEY = 'whenever'

export const IMAGE_STYLES = {
  onlyOnSB: 'w-64 h-[76px] lg:w-[72px] lg:h-[86px] ',
  dreamyDeals: 'w-10 h-10 lg:w-[86px] lg:h-[86px]',
  whenever: 'w-10 h-[53px] lg:w-[103px] lg:h-[86px]'
}

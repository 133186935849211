'use client'

import clsx from 'clsx'

import Typography from '@thebeansgroup/ui/typography'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { getIsLoggedIn } from '@helpers/auth'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import {
  DREAMY_DEALS_FALLBACK,
  DREAMY_DEALS_KEY,
  ENJOY_IN_ONE_PLACE_FALLBACK,
  GET_DISCOUNTS_FALLBACK,
  IMAGE_STYLES,
  ONLY_ON_SB_FALLBACK,
  ONLY_ON_SB_KEY,
  WHENEVER_WHEREVER_FALLBACK,
  WHENEVER_WHEREVER_KEY,
  WHY_DESCRIPTION_FALLBACK,
  WHY_TITLE_FALLBACK,
  WONT_FIND_ANYWHERE_ELSE_FALLBACK
} from './constants'
import ValuePropWhyPlaceholder from './placeholder'

const ValuePropWhy = () => {
  const { t } = useI18n()
  const { data, loading } = useUserData()
  const isLoggedIn = getIsLoggedIn(data)

  if (loading) return <ValuePropWhyPlaceholder />

  if (isLoggedIn) return null

  const renderTitle = () => {
    return (
      <div
        data-testid='why-student-beans-title'
        className='relative z-1 lg:mb-3'
      >
        <div className='block lg:hidden'>
          <Typography
            variant='title3'
            component='h2'
            fontWeight='bold'
            className='block lg:hidden'
          >
            {t('d_why_student_beans', { fallback: WHY_TITLE_FALLBACK })}
          </Typography>
        </div>
        <Typography
          variant='largeTitle'
          component='h2'
          fontWeight='bold'
          className='hidden lg:block'
        >
          {t('d_why_student_beans', { fallback: WHY_TITLE_FALLBACK })}
        </Typography>
      </div>
    )
  }

  const renderText = () => {
    return (
      <Typography
        data-testid='why-student-beans-text'
        className='relative max-w-[500px] mt-0 mb-5 mx-auto z-1 lg:max-w-[650px] lg:mb-6 !text-grey-400'
      >
        {t('m_top_rated', {
          fallback: WHY_DESCRIPTION_FALLBACK
        })}
      </Typography>
    )
  }

  const renderColumnTitle = (title) => {
    return (
      <div>
        <Typography
          variant='title3'
          component='h3'
          fontWeight='semibold'
          className='block lg:hidden'
        >
          {title}
        </Typography>
        <Typography
          variant='title3'
          component='h3'
          fontWeight='semibold'
          className='hidden lg:block mb-2 text-grey-500'
        >
          {title}
        </Typography>
      </div>
    )
  }

  const renderColumn = (valueNumber, imagePath, name, title, text) => {
    return (
      <div
        data-testid={`why-student-beans-value-${valueNumber}`}
        className='relative flex flex-row text-left mb-4 items-center z-1 last-of-type:mb-0 lg:mb-0 lg:text-center lg:flex-col'
      >
        <div
          className={clsx('relative flex-shrink-0 mr-4', IMAGE_STYLES[name])}
        >
          <SafeImage
            src={imagePath}
            alt=''
            fill
          />
        </div>
        <div>
          {renderColumnTitle(title)}
          <Typography className='!text-grey-400'>{text}</Typography>
        </div>
      </div>
    )
  }

  const renderColumns = () => {
    return (
      <div className='flex flex-col items-center justify-center lg:flex-row lg:gap-5'>
        {renderColumn(
          1,
          withAssetPrefix('images/value_prop_why/only_sb.png'),
          ONLY_ON_SB_KEY,
          t('d_only_on_sb', { fallback: ONLY_ON_SB_FALLBACK }),
          t('m_wont_find_anywhere', {
            fallback: WONT_FIND_ANYWHERE_ELSE_FALLBACK
          })
        )}

        {renderColumn(
          2,
          withAssetPrefix('images/value_prop_why/dreamy_deals.png'),
          DREAMY_DEALS_KEY,
          t('d_dreamy_deals', { fallback: DREAMY_DEALS_FALLBACK }),
          t('m_enjoy_in_one_place', {
            fallback: ENJOY_IN_ONE_PLACE_FALLBACK
          })
        )}

        {renderColumn(
          3,
          withAssetPrefix('images/value_prop_why/whenever.png'),
          WHENEVER_WHEREVER_KEY,
          t('d_whenever_wherever', { fallback: WHENEVER_WHEREVER_FALLBACK }),
          t('m_get_discounts_website_or_app', {
            fallback: GET_DISCOUNTS_FALLBACK
          })
        )}
      </div>
    )
  }

  const renderGraphics = () => {
    return (
      <div className='hidden xl:absolute xl:left-0 xl:right-0 xl:block xl:z-0 xl:pointer-events-none xl:mt-[-350px]'>
        <div className='absolute top-0 w-[213px] h-[327px] left-0 xl:top-[-18px] xl:left-[-60px]'>
          <SafeImage
            src={withAssetPrefix('images/value_prop_why/left_graphic.png')}
            fill
            alt=''
          />
        </div>
        <div className='absolute top-0 w-[205px] h-[213px] right-0'>
          <SafeImage
            src={withAssetPrefix('images/value_prop_why/right_graphic.png')}
            fill
            alt=''
          />
        </div>
      </div>
    )
  }

  return (
    <section className='my-9 text-center'>
      {renderTitle()}
      {renderText()}
      {renderColumns()}
      {renderGraphics()}
    </section>
  )
}

export default ValuePropWhy
